var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-inner",on:{"click":function($event){return _vm.mapClick($event)}}},[_c('gmap-map',{ref:"mapRef",staticStyle:{"width":"100%","height":"400px"},attrs:{"center":_vm.center,"zoom":14,"options":{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false
    }}},_vm._l((_vm.runs),function(run,index){return _c('gmap-marker',{key:index,ref:run.id,refInFor:true,attrs:{"icon":_vm.getIcon(run),"clickable":true,"position":{lat: run.donor.latitude, lng: run.donor.longitude}},on:{"click":function($event){return _vm.selectRun(run)}}})}),1),_c('div',{staticClass:"map-card"},[(_vm.selectedRun.id)?_c('run-card',{attrs:{"removable":false,"run":_vm.selectedRun}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }