<template>
  <div class="map-inner" @click="mapClick($event)">
    <gmap-map
      ref="mapRef"
      :center="center"
      :zoom="14"
      style="width:100%;  height: 400px;"
      :options="{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false
      }"
    >
      <gmap-marker
        :key="index"
        :ref="run.id"
        v-for="(run, index) in runs"
        :icon="getIcon(run)"
        :clickable="true"
        :position="{lat: run.donor.latitude, lng: run.donor.longitude}"
        @click="selectRun(run)"
      ></gmap-marker>
    </gmap-map>
    <div class="map-card">
      <run-card v-if="selectedRun.id" :removable="false" :run="selectedRun" />
    </div>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps';

import RunCard from '../components/run-card';

export default {
  name: 'google-map',
  props: {
    request: {
      type: Object
    },
    runs: {
      type: Array
    },
    selectable: {
      type: Boolean,
      default: true
    }
  },
  components: { RunCard },
  data () {
    return {
      map: '',
      defaultCenter: { lat: 22.2858919, lng: 114.1637425 },
      center: { lat: 22.2858919, lng: 114.1637425 },
      selectedRun: {}
    };
  },
  computed: {
    google: gmapApi
  },
  mounted () {
    this.setMapZoom();
  },
  methods: {
    mapClick (e) {
      if (!e.target.hasAttribute('src')) {
        this.selectRun({});
      }
    },
    selectRun (run) {
      if (this.selectable) {
        this.selectedRun = run;
        this.$emit('select-run', run);
      }
    },
    getIcon (run) {
      let pin = 'blue';
      if (run.expectedAmount === '1-20') {
        pin = 'orange';
      } else if (run.expectedAmount === '21-30') {
        pin = 'light-green';
      } else if (run.expectedAmount === '31+') {
        pin = 'green';
      }

      if (this.selectedRun.id === run.id) {
        pin += '-vib';
      } else if (this.request && this.request.runs.find(item => item.id === run.id)) {
        pin += '-check';
      }

      return '/images/pin-' + pin + '.svg';
    },
    setCenter () {
      this.center = this.defaultCenter;

      if (this.runs.length) {
        let donor = this.runs[0].donor;
        this.center = { lat: donor.latitude, lng: donor.longitude };
      }
    },
    setMapZoom () {
      if (this.runs.length <= 1) {
        return this.setCenter();
      }

      if (this.google) {
        var bounds = new this.google.maps.LatLngBounds();
        this.runs.map(run => {
          bounds.extend(new this.google.maps.LatLng(run.donor.latitude, run.donor.longitude));
        });

        this.$refs.mapRef.fitBounds(bounds);
      }
    }
  },
  watch: {
    runs: 'setMapZoom'
  }
};
</script>
