<template>
  <div class="runs view">
    <div class="detail-top">
      <div class="nav-info">
        <v-btn class="back-link" icon @click="$router.push({name: 'runs'})">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <dropoff-point :dropoff="currentRun.dropoff" :dropoff-list="currentRun.dropofflist"></dropoff-point>
      </div>
      <run-card read-only :run="currentRun" />
    </div>
    <div class="detail-bottom" :class="{'saved':buns}">
      <v-progress-linear
        :value="buns ? 100 : 50"
        background-color="#F8DAC6"
        height="5"
        color="#93D4D6" />
      <div class="bun-form">
        <p v-if="buns" class="text">{{ $t("runview.thanks") }}</p>
        <p v-else class="text">{{ $t("runview.question") }}</p>
        <div class="form-block" :class="{'saved':buns}">
          <div class="input-wrap">
            <img v-if="buns" src="/images/bun-saved.svg">
            <img v-else src="/images/bun.svg">
            <input :disabled="buns" :placeholder="placeholder" v-model.number="collectedAmount">
          </div>
          <v-btn v-if="buns" text class="bun-btn" @click="conditionalConfirm" :disabled="buns">
            <v-icon>mdi-check</v-icon>
            <span>{{ $t("runview.saved") }}</span>
          </v-btn>
          <v-btn v-else text class="bun-btn" @click="conditionalConfirm" :disabled="buns || tooEarlyToInput">
            {{ $t("runview.confirm") }}
          </v-btn>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="dialog"
      max-width="515"
      content-class="warning-dialog"
    >
      <v-card>
        <v-card-title class="headline text-center">{{ $t("runview.sure") }}</v-card-title>
        <v-card-text>
          <div>
            {{ $t("runview.late") }}
          </div>
          <div>
            {{ $t("runview.call") }}
          </div>
          <div>
            {{ $t("runview.contact") }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="modal-btn cancel"
            text
            @click="dialog = false"
          >
            {{ $t("rundate.cancel") }}
          </v-btn>

          <v-btn
            class="modal-btn ok"
            text
            @click="confirm"
          >
            {{ $t("runview.zero") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="map-wrapper">
      <google-map :runs="[currentRun]" />
    </div>
  </div>
</template>

<script>
import * as runService from '@/services/run';
import GoogleMap from '../../components/google-map';
import RunCard from '../../components/run-card';
import DropoffPoint from '@/components/dropoff-point';

export default {
  components: { GoogleMap, RunCard, DropoffPoint },
  metaInfo: {
    title: 'Run view'
  },
  data: () => ({
    buns: false,
    collectedAmount: 30,
    dialog: false,
    currentRun: null
  }),
  created () {
    this.getRun();
  },
  computed: {
    placeholder () {
      return this.$t('runview.amount');
    },
    tooEarlyToInput () {
      if (new Date(this.currentRun.date) - new Date() > 0) {
        return true;
      } else {
        return false;
      }
    },
    wastingBread () {
      if (this.collectedAmount === 0 && this.currentRun.expectedAmount === null) {
        return false;
      } else if (this.collectedAmount === 0 && this.currentRun.expectedAmount !== '0') {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    getRun () {
      let currentRun = this.$store.getters.myRuns.find(run => run.id === this.$route.params.id);
      if (currentRun) {
        this.currentRun = currentRun;
        this.collectedAmount = this.currentRun.collectedAmount;
      } else {
        runService.view(this.$route.params.id)
          .then(response => {
            this.currentRun = response[0];
            this.collectedAmount = this.currentRun.collectedAmount;
          });
      }
    },
    openDialog () {
      this.dialog = true;
    },
    conditionalConfirm () {
      if (this.wastingBread) {
        this.dialog = true;
      } else {
        this.confirm();
      }
    },
    confirm () {
      this.dialog = false;
      runService.update(this.currentRun.id, { collectedAmount: this.collectedAmount })
        .then(() => {
          this.buns = true;
          this.$store.dispatch('loadRuns');
          setTimeout(() => {
            this.$router.push('/runs');
          }, 2000);
        });
    }
  }
};
</script>
